import React, { useState } from "react";
import Stars from "./Stars";



function Faq() {

    const ReadMore = ({ children }) => {
        const text = children;
        const [isReadMore, setIsReadMore] = useState(true);
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore);
        };
        return (
            <p className="text">
                {isReadMore ? text.slice(0, 100) : text}
                <span onClick={toggleReadMore} className="read-or-hide text-gray-400">
                    {isReadMore ? "...read more" : " show less"}
                </span>
            </p>
        );
    };
    const moverReview = [
        {
            name: "Angeles Carolina G.",
            date: "March 11, 2022",
            // tittle: "Movers on the way is the best!",
            description: "On time! Affordable, professionals. I will definite recommend Jaramillo Installers to my family and friends. Thanks!",
            rate: 5,
            red: "Porch",
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/porch.png?alt=media&token=b88bfffe-c6bd-4759-9aac-6b2343b0c012',
        },
        {
            name: "Larry O.",
            date: "March 28, 2019",
            // tittle: "Movers on the way is the best!",
            description: "I can say with all conviction you will be 100% satisfied with the work, quality materials, professionalism and fair pricing. Big thanks goes to Jose and Jerry for an excellent job. These guys are true professionals. You will not regret hiring this company for a quality job. Well done guys!",
            rate: 5,
            red: "Porch",
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/porch.png?alt=media&token=b88bfffe-c6bd-4759-9aac-6b2343b0c012',

        },
        {
            name: "Alan G.",
            date: "August 29, 2017",
            // tittle: "Movers on the way is the best!",
            description: "Jose came out within a couple hours of my request to quote the job. Was very reasonable and the job he did couldn't have been more professional. Insulation can get messy and they cleaned up after themselves and took all the waste away. Couldn't be happier. Your in good hands with Jaramillo Installers!!!",
            rate: 5,
            enlace: 'https://porch.com/elgin-il/insulation-contractors/jaramillo-installers/pp#reviews',
            ima: 'https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/porch.png?alt=media&token=b88bfffe-c6bd-4759-9aac-6b2343b0c012',
            red: "Porch",
        },
        
    ];


    return (
        <section className="w-4/5 h-auto my-10 mx-auto gap-5 grid md:grid-cols-3 grid-cols-1">
            {
                moverReview.map((item, index) => {
                    return (
                        <article key={index} className="h-auto p-2 flex flex-col rounded-md bg-gray-100 w-auto">
                            <div className="flex flex-col">
                                <div className="p-2 flex space-x-2">
                                    <div className="flex justify-center">
                                        <img src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/user.jfif?alt=media&token=2e7c59b5-a71c-4739-8260-bb88b37b08fa"
                                            alt="perfil-foto"
                                            className="w-[50px] h-auto rounded-full object-cover" />
                                    </div>
                                    <div>
                                        <span className="text-[20px] font-semibold"> {item.name} </span>
                                        <Stars score={item.rate} />
                                    </div>
                                    <div className="flex items-end text-[13px] text-gray-400">
                                        <span>{item.date}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="p-5 ">
                                <ReadMore >{item.description}</ReadMore>
                            </div>
                            <div className="w-full flex items-center space-x-3 ml-3">
                                <div className="w-[25px] h-[25px] flex items-center">
                                    <img src={item.ima} alt="agni" className="w-full mx-auto"/>
                                </div>
                                <div className="flex flex-col items-center justify-start">
                                    <p>Posted On</p>
                                    <a className="text-blue-500 w-full" href={item.enlace}>{item.red}</a>
                                </div>
                            </div>
                        </article>
                    );
                })
            }
        </section>
    );
}

export default Faq;