import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
// import { ButtonContent } from "../global/boton/ButtonContent";

function ServicesHome({image1}) {
  const { rpdata } = useContext(GlobalDataContext);
  
  let imgChange = (e) => {
    document.querySelector('.bg_image').style
    .backgroundImage = `url(${e})`;
}
  return (
    <>
      <section className="main_banner">
        <ul className="ul">
    {
          rpdata?.dbServices?.slice(0, 3).map((serv, index) => {
            return (
              <div key={index}>
              {
                serv?.description?.slice(0, 1).map((item) => {
                  return (
                    <div>
                      <li onMouseOver={() => imgChange(item.img)} className="li activate">
                        <a href="/services" rel="noopener noreferrer">{serv.name}</a>
                      </li>
                      <div className="apar">
                        <p className="textobg">{item.text.substring(0, 170)}...</p>
                      </div>
                    </div>
                  )
                })
              }
              </div>
              )
            })
          }
          </ul>
      <div className="bg_image opacity-75"></div>
      </section>
    </>
  );
}

export default ServicesHome;
